export function getUtms(){
  const urlParams = new URLSearchParams(window.location.search)

  const utmSource = urlParams.get('utm_source') || ''
  const utmMedium = urlParams.get('utm_medium') || ''
  const utmCampaign = urlParams.get('utm_campaign') || ''

  return {
    utm_source: utmSource,
    utm_medium: utmMedium,
    utm_campaign: utmCampaign
  }
}
