export const buttonTokens = {
  borderWidths: {
    buttonPrimaryBorderWidth: '$none',
    buttonPrimaryBorderWidthHover: '$none',
    buttonPrimaryBorderWidthActive: '$none',
    buttonPrimaryBorderWidthDisabled: '$none',

    buttonSecondaryBorderWidth: '$thin',
    buttonSecondaryBorderWidthHover: '$thin',
    buttonSecondaryBorderWidthActive: '$thin',
    buttonSecondaryBorderWidthDisabled: '$thin',

    buttonTertiaryBorderWidth: '$none',
    buttonTertiaryBorderWidthHover: '$none',
    buttonTertiaryBorderWidthActive: '$none',
    buttonTertiaryBorderWidthDisabled: '$none',
  },
  colors: {
    buttonPrimaryBackgroundColor: '$primaryMedium',
    buttonPrimaryBackgroundColorMobile: '$primaryUltralight',
    buttonPrimaryBackgroundColorHover: '$primaryDark',
    buttonPrimaryBackgroundColorActive: '$primaryDark',
    buttonPrimaryBackgroundColorDisabled: '$primaryUltralight',

    buttonPrimaryBorderColor: 'transparent',
    buttonPrimaryBorderColorHover: 'transparent',
    buttonPrimaryBorderColorActive: 'transparent',
    buttonPrimaryBorderColorDisabled: 'transparent',

    buttonPrimaryColor: '$neutralUltralight',
    buttonPrimaryColorHover: '$neutralUltralight',
    buttonPrimaryColorActive: '$neutralUltralight',
    buttonPrimaryColorDisabled: '$neutralDark',

    buttonSecondaryBackgroundColor: '$secondaryMedium',
    buttonSecondaryBackgroundColorHover: '$secondaryDark',
    buttonSecondaryBackgroundColorActive: '$secondaryDarkest',
    buttonSecondaryBackgroundColorDisabled: '$secondaryLightest',

    buttonSecondaryBorderColor: '$secondaryMedium',
    buttonSecondaryBorderColorHover: 'currentColor',
    buttonSecondaryBorderColorActive: 'currentColor',
    buttonSecondaryBorderColorDisabled: 'currentColor',

    buttonSecondaryColor: '$neutralUltralight',
    buttonSecondaryColorHover: '$neutralUltralight',
    buttonSecondaryColorActive: '$neutralUltralight',
    buttonSecondaryColorDisabled: '$neutralLight',

    buttonTertiaryBackgroundColor: '$neutralUltralight',
    buttonTertiaryBackgroundColorHover: '$neutralLight',
    buttonTertiaryBackgroundColorActive: '$neutralMedium',
    buttonTertiaryBackgroundColorDisabled: '$neutralDark',

    buttonTertiaryBorderColor: 'currentColor',
    buttonTertiaryBorderColorHover: 'currentColor',
    buttonTertiaryBorderColorActive: 'currentColor',
    buttonTertiaryBorderColorDisabled: 'currentColor',

    buttonTertiaryColor: '$neutralDarkest',
    buttonTertiaryColorHover: '$neutralDarkest',
    buttonTertiaryColorActive: '$neutralDarkest',
    buttonTertiaryColorDisabled: '$neutralMedium',
  },
  fonts: {
    buttonFontFamily: '$secondary',
  },
  fontSizes: {
    buttonFontSize: '$xxs'
  },
  fontWeights: {
    buttonFontWeight: '$bold'
  },
  radii: {
    buttonBorderRadius: '0.5rem'
  },
  space: {
    buttonPaddingMedium: '4rem'
  },
  sizes: {
    buttonIconWidthMedium: '40px',
    buttonHeightSmall: '32px',
    buttonHeightMedium: '48px',
    buttonHeightLarge: '64px'
  },
  shadows: {},
  opacities: {},
  lineHeights: {},
  zIndices: {}
}
