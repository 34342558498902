import { Container, styled } from "@escaletech/delta"

export const Content = styled('div', {
  '--header-height': '3.5rem',

  width: '100%',
  minHeight: '100vh',
  position: 'relative'
})

export const Wrapper = styled(Container, {
  '--paddingX': 'var(--space-xxs)',

  maxWidth: 'calc(558px + (var(--paddingX) * 2))',
  padding: '$sm var(--paddingX)',
  margin: '0 auto',

  '@lg': {
    '--paddingX': 'var(--space-lg)',

    padding: '$lg var(--paddingX)'
  }
})

export const ProgressBar = styled('div', {
  position: 'absolute',
  top: 'var(--header-height)',
  left: 0,
  right: 0,

  '@lg': {
    top: 0,
  }
})

export const Navigation = styled('div', {
  background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.7) 13.02%, rgba(255, 255, 255, 0.8) 26.56%, #FFFFFF 52.08%)',
  zIndex: '$alwaysOnTop',
  padding: '$xxxs 0',
  marginTop: '$xxs',

  '& > div': {
    position: 'static',
    padding: 0,
  }
})
