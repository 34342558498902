import React from 'react'
import { Grid } from '@escaletech/delta'

import { Header } from '../../components/Header'
import { Sidebar } from '../../components/Sidebar'

import * as S from "./BaseLayout.styles"

type TBaseLayoutProps = {
  children: React.ReactNode
  navigation?: JSX.Element
  progressBar: JSX.Element
}

export const BaseLayout = (
{
  children,
  navigation,
  progressBar
}: TBaseLayoutProps) => {
  return (
    <>
      <Grid gap="0" css={{ width: '100%' }}>
        <Grid.Item
          colSpans={{ md: 5 }}
          css={{
            display: 'none',

            '@lg': {
              display: 'block'
            }
          }}
        >
          <Sidebar />
        </Grid.Item>

        <Grid.Item colSpans={{ md: 12, lg: 7 }}>
          <S.Content>
            <Header />

            <S.ProgressBar>
              {progressBar}
            </S.ProgressBar>

            <S.Wrapper>
              {children}

              {!!navigation && (
                <S.Navigation>
                  {navigation}
                </S.Navigation>
              )}
            </S.Wrapper>
          </S.Content>
        </Grid.Item>
      </Grid>
    </>
  )
}
