import React from 'react'
import { TStepProps, TValidator } from '@escaletech/quiz'
import { TextInput, RequiredField } from '@packages/components'

import { QuestionHeader } from '../../../components/QuestionHeader'
import { OptInGroup } from '../../../components/OptInGroup'

import { BaseLayout } from '../../../layouts/BaseLayout'

import { cnpjMask, phoneMask } from '../../../utils/masks'
import { isCnpjValid } from '../../../utils/isCnpjValid'
import { isEmailValid } from '../../../utils/isEmailValid'
import { isPhoneValid } from '../../../utils/isPhoneValid'

import * as S from './PMEContact.styles'

export function PMEContact({ ProgressBar, Navigation }: TStepProps){
  return (
    <BaseLayout navigation={Navigation} progressBar={ProgressBar}>
      <QuestionHeader
        title="Por último, informe seu contato."
        description='Um dos nossos especialistas entrará em contato com uma cotação personalizada.'
      />

      <S.FieldGroup>
        <TextInput
          label="Nome"
          name="firstname"
          id="firstname"
          required
          validator={RequiredField}
        />

        <TextInput
          label="E-mail"
          name="email"
          id="email"
          required
          validator={{
            func: (fieldValue = '') => isEmailValid(fieldValue),
            msg: (fieldValue = '') => {
              if (!fieldValue.trim()) {
                return 'Campo obrigatório'
              }

              return 'E-mail inváldo'
            }
          } as TValidator}
        />

        <TextInput
          label="Telefone com DDD"
          name="phone"
          id="phone"
          mask={phoneMask}
          required
          validator={{
            func: (fieldValue = '') => isPhoneValid(fieldValue),
            msg: (fieldValue = '') => {
              if (!fieldValue.trim()) {
                return 'Campo obrigatório'
              }

              return 'Telefone inváldo'
            }
          } as TValidator}
        />

        <TextInput
          label="CNPJ"
          name="cnpj"
          id="cnpj"
          mask={cnpjMask}
          required
          validator={{
            func: (fieldValue = '') => isCnpjValid(fieldValue),
            msg: (fieldValue = '') => {
              if (!fieldValue.trim()) {
                return 'Campo obrigatório'
              }

              return 'CNPJ inváldo'
            }
          } as TValidator}
        />
      </S.FieldGroup>

      <OptInGroup />
    </BaseLayout>
  )
}
