import { styled } from "@escaletech/delta"

export const Wrapper = styled('div', {
  textAlign: 'center',
  marginTop: '$xhuge',

  '@md': {
    textAlign: 'start',
  }
})
