import React, { FormEvent } from 'react'
import { TGetFieldByName, TQuizProps } from '@escaletech/quiz'
import { Button } from '@escaletech/delta'

import {
  Intro,
  HiringAndUse,
  Employees,
  PMEContact,
  Success,
  DependentsAndDateOfBirth,
  AccommodationAndPriorities,
  Budget,
  PMEDetailedContact,
  ProfessionAndDateOfBirth,
  IndividualContact,
  IndividualDetailedContact,
  Dependents,
  RequestQuotationError,
  DentalPlan
} from './steps'

import { getLeadData, saveLeadData } from '../services/leadData'

export const QuizProps: TQuizProps = {
  localStorageKey: 'zelasQuiz',
  rootPath: '/quiz',
  onStepMount: () => {
    window?.scrollTo?.(0, 0)
    window?.LeadTracking?.pageView?.()

    const inputs = document.getElementsByTagName('input')
    const inputsList = Array.prototype.slice.call(inputs)

    for (const input of inputsList) {
      input.oninvalid = (e: FormEvent<HTMLInputElement>) => {
        input.focus()
        e.currentTarget.scrollIntoView({
          block: 'nearest'
        })
      }
    }
  },
  onNextStep: async () => {
    const leadData = getLeadData()

    try {
      await saveLeadData(leadData)
    } catch (error) {
      console.log({ error})
    }
  },
  progressBar: {
    backgroundColor: '#B8E7FF',
    progressColor: '#1498FA'
  },
  navigation: {
    PrevComponent: ({ className, id }) => (
      <Button
        type="button"
        variant="tertiary"
        id={id}
        className={`prevButton ${className}`}
      >
        Voltar
      </Button>
    ),
    NextComponent: ({ className, id }) => (
      <Button
        type="submit"
        id={id}
        size="large"
        className={`nextButton navButton ${className}`}
      >
        Continuar
      </Button>
    )
  },
  steps: [
    {
      path: '/',
      Component: Intro,
      progress: 10,
      nextSteps: (getFieldByName) => {
        const planType = getFieldByName('tipo_plano___quiz')
        return planType === "Contratar Plano de Saúde" ? '/contratacao-e-utilizacao' : '/planos-odonto'
      }
    },
    {
      path: '/planos-odonto',
      Component: DentalPlan,
      progress: 20,
      prevSteps: () => '/',
    },
    {
      path: '/contratacao-e-utilizacao',
      Component: HiringAndUse,
      progress: 20,
      prevSteps: () => '/',
      nextSteps: (getFieldByName) => {
        const documentType = getFieldByName('tipo_de_contratacao___quiz')
        const interestedPublic = getFieldByName('publico_de_interesse___quiz')

        const isPME = interestedPublic === 'Sua empresa e funcionarios'

        if (isPME) {
          return '/pme-vidas'
        }

        const isPMEAndFamiliar = (documentType === 'CNPJ/MEI') && (interestedPublic === 'Voce e sua família')

        if(isPMEAndFamiliar){
          return '/familiar-cnpj-dependentes'
        }

        const isIndividualAndFamiliar = (documentType === 'CPF') && (interestedPublic === 'Voce e sua família')

        if(isIndividualAndFamiliar){
          return '/familiar-cpf-ocupacao'
        }

        const isIndividual = interestedPublic === 'Voce'

        if(isIndividual){
          return '/individual-ocupacao'
        }

        return '/'
      }
    },
    {
      path: '/pme-vidas',
      Component: Employees,
      progress: 30,
      prevSteps: () => '/contratacao-e-utilizacao',
      nextSteps: () => {
        const addressStoraged = localStorage.getItem('@zelasQuiz/userAddress') ?? '{}'

        if(!addressStoraged){
          return '/contratacao-e-utilizacao'
        }

        const address = JSON.parse(addressStoraged)

        if(address.state === 'SP'){
          return '/pme-contato'
        }

        return '/pme-contato-cotacao'
      },
    },
    {
      path: '/pme-contato',
      Component: PMEContact,
      progress: 40,
      prevSteps: () => '/pme-vidas',
      nextSteps: () => '/obrigado'
    },
    {
      path: '/pme-contato-cotacao',
      Component: PMEDetailedContact,
      progress: 40,
      prevSteps: () => '/pme-vidas',
      nextSteps: () => '/obrigado'
    },
    {
      path: '/familiar-cnpj-dependentes',
      Component: DependentsAndDateOfBirth,
      progress: 30,
      prevSteps: () => '/contratacao-e-utilizacao',
      nextSteps: () => '/familiar-cnpj-acomodacao'
    },
    {
      path: '/familiar-cnpj-acomodacao',
      Component: AccommodationAndPriorities,
      progress: 40,
      prevSteps: () => '/familiar-cnpj-dependentes',
      nextSteps: () => '/familiar-cnpj-orcamento'
    },
    {
      path: '/familiar-cnpj-orcamento',
      Component: Budget,
      progress: 50,
      prevSteps: () => '/familiar-cnpj-acomodacao',
      nextSteps: () => {
        const addressStoraged = localStorage.getItem('@zelasQuiz/userAddress') ?? '{}'

        const address = JSON.parse(addressStoraged)

        const userIsFromSP = address.state === 'SP'

        if(userIsFromSP){
          return '/familiar-cnpj-contato'
        }

        return '/familiar-cnpj-contato-cotacao'
      }
    },
    {
      path: '/familiar-cnpj-contato',
      Component: PMEContact,
      progress: 60,
      prevSteps: () => '/familiar-cnpj-orcamento',
      nextSteps: (getFieldByName) => userAcceptedDataSharing(getFieldByName)
    },
    {
      path: '/familiar-cnpj-contato-cotacao',
      Component: PMEDetailedContact,
      progress: 60,
      prevSteps: () => '/familiar-cnpj-orcamento',
      nextSteps: (getFieldByName) => userAcceptedDataSharing(getFieldByName)
    },

    {
      path: '/familiar-cpf-ocupacao',
      Component: ProfessionAndDateOfBirth,
      progress: 30,
      prevSteps: () => '/contratacao-e-utilizacao',
      nextSteps: () => '/familiar-cpf-vidas'
    },
    {
      path: '/familiar-cpf-vidas',
      Component: Dependents,
      progress: 40,
      prevSteps: () => '/familiar-cpf-ocupacao',
      nextSteps: () => '/familiar-cpf-acomodacao'
    },
    {
      path: '/familiar-cpf-acomodacao',
      Component: AccommodationAndPriorities,
      progress: 50,
      prevSteps: () => '/familiar-cpf-vidas',
      nextSteps: () => '/familiar-cpf-orcamento'
    },
    {
      path: '/familiar-cpf-orcamento',
      Component: Budget,
      progress: 60,
      prevSteps: () => '/familiar-cpf-acomodacao',
      nextSteps: () => {
        if(isUserRegionAvailable()){
          return '/familiar-cpf-contato'
        }

        return '/familiar-cpf-contato-cotacao'
      }
    },
    {
      path: '/familiar-cpf-contato',
      Component: IndividualContact,
      progress: 70,
      prevSteps: () => '/familiar-cpf-orcamento',
      nextSteps: () => '/obrigado'
    },
    {
      path: '/familiar-cpf-contato-cotacao',
      Component: IndividualDetailedContact,
      progress: 70,
      prevSteps: () => '/familiar-cpf-orcamento',
      nextSteps: () => '/obrigado'
    },
    {
      path: '/individual-ocupacao',
      Component: ProfessionAndDateOfBirth,
      progress: 30,
      prevSteps: () => '/contratacao-e-utilizacao',
      nextSteps: () => '/individual-acomodacao'
    },
    {
      path: '/individual-acomodacao',
      Component: AccommodationAndPriorities,
      progress: 40,
      prevSteps: () => '/individual-ocupacao',
      nextSteps: () => '/individual-orcamento'
    },
    {
      path: '/individual-orcamento',
      Component: Budget,
      progress: 50,
      prevSteps: () => '/individual-acomodacao',
      nextSteps: () => {
        if(isUserRegionAvailable()){
          return '/individual-contato'
        }

        return '/individual-contato-cotacao'
      }
    },
    {
      path: '/individual-contato',
      Component: IndividualContact,
      progress: 60,
      prevSteps: () => '/individual-orcamento',
      nextSteps: (getFieldByName) => userAcceptedDataSharing(getFieldByName)
    },
    {
      path: '/individual-contato-cotacao',
      Component: IndividualDetailedContact,
      progress: 60,
      prevSteps: () => '/individual-orcamento',
      nextSteps: (getFieldByName) => userAcceptedDataSharing(getFieldByName)
    },
    {
      path: '/erro-ao-solicitar-cotacao',
      Component: RequestQuotationError,
      progress: 90
    },
    {
      path: '/obrigado',
      Component: Success,
      progress: 100
    }
  ]
}

function isUserRegionAvailable(){
  const addressStoraged = localStorage.getItem('@zelasQuiz/userAddress') ?? '{}'

  const address = JSON.parse(addressStoraged)

  const availableCities = [
    'Atibaia',
    'Barueri',
    'Bragança Paulista',
    'Cotia',
    'Diadema',
    'Guarulhos',
    'Itaperica da Serra',
    'Jundiaí',
    'Mogi das Cruzes',
    'Osasco',
    'Santo André',
    'São Bernardo do Campo',
    'São Paulo',
    'Suzano',
    'Tuiuti',
  ]

  return address.state === 'SP' && availableCities.includes(address.city)
}

function userAcceptedDataSharing(getFieldByName: TGetFieldByName){
  const acceptedOptIn = getFieldByName('opt_in_parceiros')

  if(acceptedOptIn !== 'accepted'){
    return '/erro-ao-solicitar-cotacao'
  }

  return '/obrigado'
}
