import React, { useContext, useEffect, useState } from 'react'
import { QuizContext } from '@escaletech/quiz'
import { IInputNumberBlock, ValidatorDefaultValue, FieldError } from '@packages/components'
import InputNumberItem from './Item'
import * as S from './styles'
import { styled } from '@escaletech/delta'

const Holder = styled('div', {})
const InputNumber: React.FC<IInputNumberBlock> = ({
  leftLabel,
  rightLabel,
  inputs,
  name,
  validator = ValidatorDefaultValue,
  showTotal,
  css,
  className
}) => {
  const [isValid, setIsValid] = useState(true)
  const { getFieldByName, setFieldValue, validateField, deleteField } = useContext(QuizContext)
  const fieldValue = getFieldByName(name) || ''
  const getTotal = () =>
    inputs?.reduce((prev, curr) => {
      const currInput = getFieldByName(curr.name)
      const currValue = currInput ? ~~currInput : 0
      return prev + currValue
    }, 0)

  useEffect(() => {
    const currentParentValue = `${getTotal()}`
    if (fieldValue !== currentParentValue) {
      setFieldValue(name, currentParentValue)
    }

    setIsValid(validateField(name, validator))
  }, [fieldValue, getFieldByName, inputs, name, setFieldValue, validateField, validator])

  const onChange = (inputName: string, i: number) => {
    setFieldValue(inputName, `${i}`)

    if (i === 0) {
      deleteField(inputName)
    }
  }

  return (
    <Holder css={{ ...css }}>
      <S.InputNumber>
        <S.Header>
          {leftLabel &&
            <label>
              {leftLabel}
            </label>
          }
          {rightLabel &&
            <label>
              {rightLabel}
            </label>
          }
        </S.Header>
        <S.Body>
          <S.HiddenInput type="text" name={name} />
          {inputs && inputs.map((option, key) => {
            const slugId = option.label.replace(/[\s-]/g, '_')
            const input = getFieldByName(option.name)
            const value = !!input ? ~~input : 0

            return (
              <S.Row key={key}>
                <S.LabelNumber>
                  {option.label}
                </S.LabelNumber>
                <InputNumberItem name={option.name} id={slugId} className={className} value={value} onChange={onChange} />
              </S.Row>
            )
          })}
        </S.Body>
        {
          !!showTotal &&
          <S.Total>
            <span>Total de vidas</span>
            <S.TotalValue>{getTotal()}</S.TotalValue>
          </S.Total>
        }
        {
          !isValid && <FieldError msg={validator.msg(fieldValue)} />
        }
      </S.InputNumber>
    </Holder>
  )
}

export default InputNumber
