import { GetAddressDataByZipcodeDocument } from "@packages/services/src/apolloClient/gql/graphql"
import apolloClient from "@packages/services/src/apolloClient"
import { useContext } from "react"
import { QuizContext } from "@escaletech/quiz"

export function useIntro(){
  const { setFieldValue, getFieldByName } = useContext(QuizContext)

  const fieldValue = getFieldByName('zip_code') ?? ''

  async function getUserAddressByZipcode(zipcode: string){
    const { data } = await apolloClient.query({
      query: GetAddressDataByZipcodeDocument,
      variables: {
        zipcode
      }
    })

    if(!data.getAddressDataByZipcode?.zipcode){
      return null
    }

    const result = {
      city: data.getAddressDataByZipcode?.city ?? '',
      state: data.getAddressDataByZipcode?.state ?? '',
      zipcode: data.getAddressDataByZipcode.zipcode
    }

    return result
  }

  async function handleZipcodeChange(
    event: React.FormEvent<Element>
  ){
    const { value, name } = event.target as HTMLInputElement

    if(fieldValue.length === 8){
      const userAddress = await getUserAddressByZipcode(value.replace(/\D/g, ''))

      if(!userAddress){
        return setFieldValue(name, '')
      }

      localStorage.setItem('@zelasQuiz/userAddress', JSON.stringify(userAddress))
    }
  }

  return {
    handleZipcodeChange,
  }
}
