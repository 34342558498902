export type DomainLeadData = {
  totalNumberPeople: string
  communicationChannel: string
  accommodation: string
  values: string
  coverage: string
  companyName: string
  interestedPublic: string
  name: string
  email: string
  phone: string
  occupation: string
  documentType: string
  birthDate: string
  cpf: string
  cnpj: string
  zipcode: string
  optInMkt: 'accepted' | 'declined'
  optInPartner: 'accepted' | 'declined'
}

export type PersistenceLeadData = {
  abrangencia___quiz: string
  acomodacao___quiz: string
  valores___quiz: string
  birth_date: string
  cnpj: string
  communication_channel: string
  publico_de_interesse___quiz: string
  company_name: string
  email: string
  phone: string
  n__total_de_pessoas: string
  tipo_de_contratacao___quiz: string
  firstname: string
  cpf: string
  occupation: string
  zip_code: string
  opt_in_mkt: 'accepted' | 'declined'
  opt_in_parceiros: 'accepted' | 'declined'
}

class LeadDataMapper {
  toPersistence (domainLeadData: DomainLeadData): PersistenceLeadData {
    return {
      abrangencia___quiz: domainLeadData.coverage,
      acomodacao___quiz: domainLeadData.accommodation,
      valores___quiz: domainLeadData.values,
      tipo_de_contratacao___quiz: domainLeadData.documentType,
      publico_de_interesse___quiz: domainLeadData.interestedPublic,
      birth_date: domainLeadData.birthDate,
      cnpj: domainLeadData.cnpj,
      communication_channel: domainLeadData.communicationChannel,
      company_name: domainLeadData.companyName,
      email: domainLeadData.email,
      phone: domainLeadData.phone.replace(/\D/g, ''),
      n__total_de_pessoas: domainLeadData.totalNumberPeople,
      firstname: domainLeadData.name,
      cpf: domainLeadData.cpf.replace(/\D/g, ''),
      occupation: domainLeadData.occupation,
      zip_code: domainLeadData.zipcode,
      opt_in_mkt: domainLeadData.optInMkt,
      opt_in_parceiros: domainLeadData.optInPartner
    }
  }

  toDomain (persistenceLeadData: PersistenceLeadData): DomainLeadData {
    return {
      totalNumberPeople: persistenceLeadData.n__total_de_pessoas ?? '',
      communicationChannel: persistenceLeadData.communication_channel ?? '',
      coverage: persistenceLeadData.abrangencia___quiz ?? '',
      accommodation: persistenceLeadData.acomodacao___quiz ?? '',
      documentType: persistenceLeadData.tipo_de_contratacao___quiz ?? '',
      interestedPublic: persistenceLeadData.publico_de_interesse___quiz ?? '',
      values: persistenceLeadData.valores___quiz ?? '',
      companyName: persistenceLeadData.company_name ?? '',
      name: persistenceLeadData.firstname ?? '',
      email: persistenceLeadData.email ?? '',
      phone: persistenceLeadData.phone ?? '',
      occupation: persistenceLeadData.occupation ?? '',
      birthDate: persistenceLeadData.birth_date ?? '',
      cpf: persistenceLeadData.cpf ?? '',
      cnpj: persistenceLeadData.cnpj ?? '',
      zipcode: persistenceLeadData.zip_code ?? '',
      optInMkt: persistenceLeadData.opt_in_mkt ?? '',
      optInPartner: persistenceLeadData.opt_in_parceiros ?? '',
    }
  }
}

export default new LeadDataMapper()
