import React from 'react'
import { Heading, Paragraph } from "@escaletech/delta"

type QuestionHeader = {
  title: string
  description?: string
}

export function QuestionHeader({ title, description }: QuestionHeader){
  return (
    <>
      <Heading
        size={{
          '@initial': 'sm',
          '@md': 'md'
        }}
        lineHeight="md"
        weight="bold"
        css={{
          marginBottom: '$xxxs',

          '@md': {
            marginBottom: '$xxs'
          }
        }}
      >
        {title}
      </Heading>
      {!!description && (
        <Paragraph
          size={{
            '@initial': 'md',
            '@md': 'lg'
          }}
          css={{
            marginBottom: '$xxs',

            '@md': {
              marginBottom: '$sm'
            }
          }}
        >
          {description}
        </Paragraph>
      )}
    </>
  )
}
