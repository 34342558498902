import { styled } from "@escaletech/delta"

export const Wrapper = styled('div', {})

export const RadioGroup = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$xxxs',

  label: {
    boxSizing: 'border-box',
    paddingInline: '$xxxs',
    minWidth: '17rem'
  }
})
