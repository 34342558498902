import React from 'react'
import { Paragraph } from "@escaletech/delta"

import CES from '../../../../../components/Fields/CES'

import * as S from './Assessment.styles'

export function Assessment(){
  return (
    <S.Wrapper>
      <S.Title
        size={{
          '@initial': 'lg',
          '@md': 'xl'
        }}
        lineHeight="md"
        weight="bold"
      >
        Pronto! <span aria-label="emoji piscando" aria-labelledby="emoji piscando" role="img">😉</span>
        <br />
        Agora é só aguardar o contato do nosso consultor parceiro.
      </S.Title>

      <Paragraph
        size={{ '@initial': 'md', '@md': 'lg'  }}
        css={{ marginBottom: '$md', '@md': { marginBottom: '$xl' } }}
      >
        Já conectamos você com uma consultoria de saúde compatível com seu perfil. Em breve, um(a) especialista parceiro(a) entrará em contato para finalizar a sua cotação.
      </Paragraph>

      <S.ReviewTitle
        size={{ '@initial': 'md', '@md': 'xl' }}
        weight="bold"
        lineHeight="distant"
      >
        De 1 (muito difícil) a 5 (muito fácil), como foi pra você responder às perguntas?
      </S.ReviewTitle>

      <CES
        name="ces___quiz"
        minText="Muito difícil"
        maxText="Muito fácil"
        className="sucesso_ces"
        options={[
          { label: '1', value: '1' },
          { label: '2', value: '2' },
          { label: '3', value: '3' },
          { label: '4', value: '4' },
          { label: '5', value: '5' }
        ]}
      />
    </S.Wrapper>
  )
}
