import axios, { AxiosRequestConfig } from 'axios'

export default class HttpClient {
  baseUrl: string

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
  }

  async get<AxiosReturnType>(path: string, config?: AxiosRequestConfig) {
    return await axios.get<AxiosReturnType>(`${this.baseUrl}${path}`, config)
  }

  async post(path: string, data?: object, config?: AxiosRequestConfig) {
    return await axios.post(`${this.baseUrl}${path}`, data, config)
  }
}
