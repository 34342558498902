import { extendsTheme } from '@escaletech/delta'

import { componentTokens } from './componentTokens'

export default extendsTheme({
  borderWidths: {
    ...componentTokens.borderWidths
  },
  colors: {
    primaryUltralight: '#E1E6FA',
    primaryLightest: '#BBC8FA',
    primaryLight: '#6681EE',
    primaryMedium: '#0034D6',
    primaryDark: '#00209F',
    primaryDarkest: '#001275',

    secondaryUltralight: '#E5F6FF',
    secondaryLightest: '#B8E7FF',
    secondaryLight: '#76C8FF',
    secondaryMedium: '#1498FA',
    secondaryDark: '#046CB3',
    secondaryDarkest: '#004875',

    tertiaryUltralight: '#CCE1FF',
    tertiaryLightest: '#99C3FF',
    tertiaryMedium: '#4D95FF',
    tertiaryLight: '#0068FF',
    tertiaryDark: '#0053CC',
    tertiaryDarkest: '#003F99',

    neutralUltralight: '#fff',
    neutralLightest: '#E5E5E5',
    neutralLight: '#C7C7C7',
    neutralMedium: '#949494',
    neutralDark: '#4D4D4D',
    neutralDarkest: '#000',

    criticalUltralight: '#FBECEE',
    criticalLightest: '#F0B4BC',
    criticalLight: '#E16979',
    criticalMedium: '#D94358',
    criticalDark: '#AE3646',
    criticalDarkest: '#822835',

    successUltralight: '#E5FAF5',
    successLightest: '#99F1DE',
    successLight: '#4ADDBD',
    successMedium: '#00C99E',
    successDark: '#06A180',
    successDarkest: '#027E64',

    warningUltralight: '#FDE4D1',
    warningLightest: '#FCC8A3',
    warningLight: '#FBA566',
    warningMedium: '#F88430',
    warningDark: '#E7721E',
    warningDarkest: '#CD671E',

    infoUltralight: '#BAD5F4',
    infoLightest: '#7FACE2',
    infoLight: '#2C73C7',
    infoMedium: '#0D4F9F',
    infoDark: '#0E3C74',
    infoDarkest: '#022652',

    formFieldBorderColorInputed: '#0034D6',

    ...componentTokens.colors
  },
  fonts: {
    primary: "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    secondary: `'Nunito Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif`,
    ...componentTokens.fonts
  },
  fontSizes: {
    ...componentTokens.fontSizes
  },
  fontWeights: {
    ...componentTokens.fontWeights
  },
  lineHeights: {
    ...componentTokens.lineHeights
  },
  opacities: {
    ...componentTokens.opacities
  },
  radii: {
    ...componentTokens.radii
  },
  shadows: {
    ...componentTokens.shadows
  },
  sizes: {
    ...componentTokens.sizes,
    cardProductWidth: ''
  },
  space: {
    ...componentTokens.space,
    cardPadding: ''
  },
  zIndices: {
    ...componentTokens.zIndices
  }
})
