import { TProduct } from "@packages/components"
import HttpClient from "../HttpClient"

class Optimus {
  httpClient: HttpClient

  constructor() {
    this.httpClient = new HttpClient('https://api.escale.com.br/optimus')
  }

  async getPmeProducts(quiz: Record<string, string>, usePMEMigration: boolean) {
    try {
      const provisoryGNDIFilter = '633347638f9e7513abfe2301'
      const endpoint = usePMEMigration ? '/catalog/health-pme-migration' : '/catalog/health'
      const params = usePMEMigration ? quiz : {
        ...quiz,
        brandId: provisoryGNDIFilter
      }

      const { data } = await this.httpClient.get<TProduct[]>(endpoint, {
        params,
        headers: {
          origin: window.location.origin
        }
      })
      return data
    } catch (e) {
      console.error(e)
      return []
    }
  }

  async searchLocations(search: string) {
    try {
      const { data } = await this.httpClient.get('/catalog/health/locations', {
        params: {
          search
        },
        headers: {
          origin: window.location.origin
        }
      })
      return data
    } catch (e) {
      console.error(e)
      return []
    }
  }
}

export default new Optimus()
