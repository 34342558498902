import { Heading, Paragraph, styled } from "@escaletech/delta"

export const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',

  '@md': {
    alignItems: 'start',
    textAlign: 'left',
  }
})

export const Title = styled(Heading, {
  marginBottom: '$xxs',

  '@md': {
    marginBottom: '$xs',
  }
})

export const ReviewTitle = styled(Paragraph, {
  marginBottom: '$xxs',

  '@md': {
    marginBottom: '$xxs',
  }
})
