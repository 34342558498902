import React from 'react'
import Quiz from '@escaletech/quiz'
// change props to see the new flow
// import HealthQuizProps from './steps/HealthQuizProps'
import { QuizProps } from './new/QuizProps'
import theme, { reset } from '@packages/theme'

const HealthQuiz = () => {
  reset()

  return (
    <div className={theme}>
      <Quiz {...QuizProps} />
    </div>
  )
}

export default HealthQuiz
