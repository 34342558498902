import React, { useContext, useState } from 'react'
import { QuizContext, TStepProps } from '@escaletech/quiz'
import { Button } from '@escaletech/delta'

import { BaseLayout } from '../../../layouts/BaseLayout'

import { Assessment } from './components/Assessment'
import { Message } from './components/Message'

import * as S from './Success.styles'

export function Success({ ProgressBar }: TStepProps){
  const [assessmentIsSubmitted, setAssessmentIsSubmitted] = useState(false)
  const { getFieldByName } = useContext(QuizContext)

  const hasSelectedCES = !!getFieldByName('ces___quiz')

  function handleSendAssessment(){
    setAssessmentIsSubmitted(true)
  }

  return (
    <BaseLayout progressBar={ProgressBar}>
      <S.Wrapper>
        {assessmentIsSubmitted ? (
          <Message />
        ) : (
          <>
            <Assessment />

            <S.ButtonGroup>
              <Button
                variant="tertiary"
                icon={<S.ButtonArrow className="icon" />}
                as="a"
                href="https://saude.zelas.com.br/"
              >
                Voltar para o Portal
              </Button>

              <Button
                variant="primary"
                disabled={!hasSelectedCES}
                onClick={handleSendAssessment}
              >
                Enviar
              </Button>
            </S.ButtonGroup>
          </>
        )}
      </S.Wrapper>
    </BaseLayout>
  )
}
