import React, { useContext, useEffect, useState } from 'react'
import { QuizContext, TValidator } from '@escaletech/quiz'
import { Grid, Input, Slider as SliderDelta } from '@escaletech/delta'

import { FieldError } from '@packages/components'

import { ValidatorDefaultValue } from '../constants'

import * as S from './Slider.styles'

type SliderProps = React.ComponentProps<typeof SliderDelta> & {
  name: string
  validator: TValidator
  inputId: string
  inputLabel: string
  inputClassName?: string
}

const Slider = ({
  name,
  min,
  max,
  inputId,
  inputLabel,
  inputClassName = '',
  validator = ValidatorDefaultValue,
  css,
  ...props
}: SliderProps) => {
  const { getFieldByName, validateField, setFieldValue } = useContext(QuizContext)
  const fieldValue = getFieldByName(name) || ''

  const [isValid, setIsValid] = useState(true)
  const [inputValue, setInputValue] = useState(!!fieldValue ? fieldValue : String(min))

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value)

    const formattedValue =
      event.target.value
        .replace(/\D/g, '')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

    setInputValue(formattedValue)

    if (value < 500) {
      return setFieldValue('valores___quiz', 'Até R$500')
    }

    if (value < 1000) {
      return setFieldValue('valores___quiz', 'De R$500 a R$1.000')
    }

    return setFieldValue('valores___quiz', 'Acima de R$1.000')
  }

  useEffect(() => {
    setFieldValue(name, inputValue)
  }, [inputValue])

  useEffect(() => {
    setIsValid(validateField(name, validator))
  }, [fieldValue, name, validateField, validator])

  return (
    <S.Wrapper css={{ ...css }}>
      <Grid>
        <input type="hidden" name={name} />

        <Grid.Item colSpans={{ sm: 6, xs: 2 }} css={{ marginBottom: '$xs' }}>
          <Input
            id={inputId}
            name="valores__quiz"
            required
            label={inputLabel}
            value={inputValue}
            onChange={handleChange}
            className={inputClassName}
            maxLength={6}
          />
        </Grid.Item>

        <Grid.Item>
          <SliderDelta
            name={name}
            min={min}
            max={max}
            value={Number(inputValue.replace(/\D/g, ''))}
            onChange={handleChange}
          />
        </Grid.Item>
      </Grid>

      {!isValid && <FieldError msg={validator.msg(fieldValue)} />}
    </S.Wrapper>
  )
}

Slider.displayName = 'Slider'

export default Slider
