import React from "react"

import { Image } from '@escaletech/delta'

import logoImg from '../../assets/images/logo.svg'

import * as S from './Header.styles'

export const Header = () => {
  return (
    <S.Header>
      <a href="https://saude.zelas.com.br/" aria-label="Ir para página inicial do Zelas Saúde">
        <Image
          src={logoImg}
          alt='Zelas Saude'
          loading="eager"
          css={{
            width: '100%',
            maxWidth: '4rem'
          }}
        />
      </a>
    </S.Header>
  )
}
