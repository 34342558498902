import React, { useContext } from "react"
import { QuizContext } from '@escaletech/quiz'

import { ICES } from "../../../types"
import * as S from './styles'

const CES: React.FC<ICES> = ({ options, minText, maxText, name, value, className, css }) => {
  const { setFieldValue, getFieldByName } = useContext(QuizContext)
  const currentValue = getFieldByName(name) || value
  const updateCES = (value: string) => () => {
    setFieldValue(name, value)
  }
  return (
    <S.CES css={css}>
      <S.Options>
        {options?.map((option) =>
          <S.Option className={`${className} ${option.value === currentValue ? 'active' : ''}`}
            onClick={updateCES(option.value)}
            id={`${className}_${option.value}`}
            key={`ces-${option.value}`}>
            {option.label}
          </S.Option>)}
      </S.Options>
      <S.Labels>
        <span>{minText}</span>
        <span>{maxText}</span>
      </S.Labels>
    </S.CES>
  )
}

export default CES
