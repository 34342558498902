import React from 'react'
import { TStepProps } from '@escaletech/quiz'
import { Button, Heading, Paragraph } from '@escaletech/delta'

import { BaseLayout } from '../../../layouts/BaseLayout'

import * as S from './RequestQuotationError.styles'

export function RequestQuotationError({ ProgressBar, Navigation }: TStepProps){
  return (
    <BaseLayout progressBar={ProgressBar} navigation={Navigation}>
      <S.Wrapper>
        <Heading
          size="md"
          lineHeight="md"
          weight="bold"
          css={{
            color: '$criticalMedium',
            marginBottom: '$xxs',

            '@md': {
              marginBottom: '$xl',
            }
          }}
        >
          Não foi possível solicitar sua cotação
        </Heading>

        <Paragraph
          size={{
            "@initial": "md",
            "@md": "lg"
          }}
          css={{
            color: '$neutralDark',
          }}
        >
          Precisamos da sua autorização para compartilhar seus dados com parceiros. Só assim você receberá orçamentos e poderá comparar planos entre diferentes corretoras.

          <Paragraph
            as="strong"
            weight="bold"
            css={{
              display: 'block',
              marginTop: '$xxxs',
              marginBottom: '$xs',

              '@md': {
                marginTop: '$xs',
              }
            }}
          >
            Volte para a tela anterior e autorize o compartilhamento de dados para prosseguir.
          </Paragraph>
        </Paragraph>

        <Button
          type="button"
          onClick={() => window.history.back()}
        >
          Voltar
        </Button>
      </S.Wrapper>
    </BaseLayout>
  )
}
