import React, { ChangeEvent, useContext, useEffect, useRef, useState } from "react"
import { QuizContext } from '@escaletech/quiz'
import axios from 'axios'

import * as S from './StateAndCityDropdown.styles'
import { Select } from '../Select'
import { RequiredField } from "../constants"

type State = {
  id: string
  nome: string
  sigla: string
}

type City = {
  id: string
  nome: string
}

const StateAndCityDropdown = () => {
  const [states, setStates] = useState<State[]>([])
  const [cities, setCities] = useState<City[]>([])
  const [isLoadingTheState, setIsLoadingTheState] = useState(true)

  const isFirstRender = useRef(true)

  const { getFieldByName, setFieldValue } = useContext(QuizContext)
  const selectedState = getFieldByName('estado___uf')
  const selectedCity = getFieldByName('city')

  const handleChangeState = (event: ChangeEvent<HTMLSelectElement>) => {
    setFieldValue('estado___uf', event.target.value)
    window?.LeadTracking?.click?.(event, event.target.value)
  }

  const handleChangeCity = (event: ChangeEvent<HTMLSelectElement>) => {
    setFieldValue('city', event.target.value)
    window?.LeadTracking?.click?.(event, event.target.value)
  }

  useEffect(() => {
    if(isFirstRender.current || process.env.NODE_ENV === 'test'){
      isFirstRender.current = false
      return
    }

    setFieldValue('city', '')
    // eslint-disable-next-line
  }, [selectedState])

  useEffect(() => {
    const loadStates = async () => {
      try {
        const response = await axios.get(`
          https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome
        `)

        setStates(response?.data ?? [])
      } catch {
        setStates([])
      } finally {
        setIsLoadingTheState(false)
      }
    }

    loadStates()
  }, [])

  useEffect(() => {
    const loadCities = async () => {
      try {
        const response = await axios.get(`
          https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedState}/municipios?orderBy=nome
        `)


        setCities(response?.data ?? [])
      } catch {
        setCities([])
      }
    }

    loadCities()
  }, [selectedState])

  const selectFromCitiesIsDisabled = cities.length === 0

  return (
    <S.SelectGroup>
      <Select
        name="estado___uf"
        id="estado_quiz"
        onChange={handleChangeState}
        value={selectedState ?? ''}
        validator={RequiredField}
        disabled={isLoadingTheState}
      >
        <option value="">Estado</option>

        {states.map((state, key) => (
          <option key={`state-${key}`} value={state.sigla}>
            {state.nome}
          </option>
        ))}
      </Select>

      <Select
        name="city"
        id="cidade_quiz"
        onChange={handleChangeCity}
        value={selectedCity ?? ''}
        validator={RequiredField}
        disabled={selectFromCitiesIsDisabled}
      >
        <option value="">Cidade</option>

        {cities.map((city, key) => (
          <option  key={`city-${key}`} value={city.nome}>
            {city.nome}
          </option>
        ))}
      </Select>
    </S.SelectGroup>
  )
}

export default StateAndCityDropdown
