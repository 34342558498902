import { styled } from '@escaletech/delta'

export const Wrapper = styled('aside', {
  display: 'flex',
  minHeight: '100vh',
  height: '100%',
  width: '100%',
  backgroundColor: '#0034D6',
  paddingTop: '$lg',
  alignItems: 'start',
  justifyContent: 'center'
})

export const LogoWrapper = styled('div', {
  background: 'white',
  padding: '$xxxs $sm',
  borderRadius: '$sm'
})
