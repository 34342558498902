import { globalCss } from '@escaletech/delta'

export const reset = globalCss({
  '@font-face': [
    {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 700,
      fontDisplay: 'swap',
      src: 'local(Inter Bold), local(Inter-Bold), url("/quiz/fonts/Inter/inter-v12-latin-700.woff2") format("woff2")'
    },
    {
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontDisplay: 'swap',
      src: 'local("Nunito Sans Regular"), local("Nunito Sans-Regular"), url("/quiz/fonts/Nunito_Sans/nunito-sans-v12-latin-regular.woff2") format("woff2")'
    },
    {
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      fontDisplay: 'swap',
      src: 'local("Nunito Sans Bold"), local("Nunito Sans-Bold"), url("/quiz/fonts/Nunito_Sans/nunito-sans-v12-latin-700.woff2") format("woff2")'
    }
  ],

  '*': {
    fontFamily: '$secondary',
    boxSizing: 'border-box',
    padding: 0,
    margin: 0,
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',

    '&::before, &::after': {
      boxSizing: 'inherit'
    }
  },

  a: {
    color: 'inherit',
    textDecoration: 'none'
  },

  'body, button, input, textarea, select': {
    fontSize: '16px'
  },

  'button, .button': {
    cursor: 'pointer'
  },

  img: {
    display: 'block',
    maxWidth: '100%',
    height: 'auto'
  },

  svg: {
    width: '1em',
    height: '1em',
    fill: 'currentColor'
  },

  ul: {
    'list-style-type': 'disc'
  },

  ol: {
    'list-style-type': 'decimal'
  },

  'ul, ol': {
    'list-style-position': 'inside'
  },
})
