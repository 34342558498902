import { styled } from "@escaletech/delta"

export const Error = styled('p', {
  color: '$criticalDark',
  marginTop: '$quarck',
  fontSize: '$xxxs',
  lineHeight: '$distant',
  fontFamily: '$paragraphFontFamily',
  fontWeight: '$paragraphFontWeight',
})
