import React from 'react'
import { TStepProps } from '@escaletech/quiz'
import { TextInput, RequiredField } from '@packages/components'

import { QuestionHeader } from '../../../components/QuestionHeader'

import { BaseLayout } from '../../../layouts/BaseLayout'

import { numberMask } from '../../../utils/masks'

export function Employees({ ProgressBar, Navigation }: TStepProps){
  return (
    <BaseLayout navigation={Navigation} progressBar={ProgressBar}>
      <QuestionHeader
        title="Quantas pessoas estarão no plano?"
        description='Informe aproximadamente o número de funcionários a serem inclusos no plano.'
      />

      <TextInput
        label="Digite o número de pessoas"
        name="n__total_de_pessoas"
        id="n__total_de_pessoas"
        mask={numberMask}
        required
        validator={RequiredField}
      />
    </BaseLayout>
  )
}
