export const linkTokens = {
  borderWidths: {},
  colors: {
    linkColor: '$primaryMedium',
    linkColorActive: '$primaryDark',
    linkColorHover: '$primaryLight'
  },
  fonts: {},
  fontSizes: {},
  fontWeights: {},
  radii: {},
  space: {},
  shadows: {},
  sizes: {},
  zIndices: {}
}
