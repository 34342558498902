import React from 'react'
import { TStepProps } from '@escaletech/quiz'
import { Radio, RequiredField } from '@packages/components'
import { Alert, Paragraph } from '@escaletech/delta'

import { BaseLayout } from '../../../layouts/BaseLayout'

import { QuestionHeader } from '../../../components/QuestionHeader'

export function HiringAndUse({ ProgressBar, Navigation }: TStepProps){
  return (
    <BaseLayout navigation={Navigation} progressBar={ProgressBar}>
      <QuestionHeader
        title="Como você pretende contratar o plano?"
      />

      <Radio
        name="tipo_de_contratacao___quiz"
        validator={RequiredField}
        options={[
          {
            value: 'CNPJ/MEI',
            label: 'CNPJ/MEI',
            id: 'cnpj_mei_como_vai_contratar',
            className: 'como_vai_contratar'
          },
          {
            value: 'CPF',
            label: 'CPF',
            id: 'cpf_como_vai_contratar',
            className: 'como_vai_contratar'
          }
        ]}
        required
        css={{
          marginBottom: '$sm',

          '@md': {
            marginBottom: '$lg'
          }
        }}
      />

      <QuestionHeader
        title="Quem irá utilizar o plano?"
      />

      <Radio
        name="publico_de_interesse___quiz"
        validator={RequiredField}
        options={[
          {
            value: 'Voce',
            label: 'Você',
            id: 'voce_pra_quem_contratar',
            className: 'pra_quem_contratar'
          },
          {
            value: 'Voce e sua família',
            label: 'Você e sua família',
            id: 'voce_e_sua_familia_pra_quem_contratar',
            className: 'pra_quem_contratar'
          },
          {
            value: 'Sua empresa e funcionarios',
            label: 'Sua empresa e funcionários',
            id: 'empresa_e_funcionarios_pra_quem_contratar',
            className: 'pra_quem_contratar'
          }
        ]}
        required
        css={{ marginBottom: '$xs' }}
      />

      <Alert title='Dica da Zelas:'>
        <Paragraph>
          Os planos contratados por CNPJ podem ficar até 40% mais baratos e estão disponíveis a partir de 2 vidas, ou seja, com pelo menos 2 integrantes no plano.
        </Paragraph>
      </Alert>
    </BaseLayout>
  )
}
