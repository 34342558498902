import React, { useContext, useEffect, useState } from 'react'
import { conformToMask, Mask } from 'react-text-mask'

import { Input, styled } from '@escaletech/delta'
import { QuizContext } from '@escaletech/quiz'

import { ITextInput } from '../types'
import { ValidatorDefaultValue } from '../constants'

const Holder = styled('div', {})
const TextInput: React.FC<ITextInput> = ({
  name,
  id,
  className,
  mask,
  value,
  label,
  required,
  validator = ValidatorDefaultValue,
  helperText,
  css,
  placeholder,
  ...props
}) => {
  const [isValid, setIsValid] = useState(true)
  const { setFieldValue, validateField, getFieldByName, triedToSubmit } = useContext(QuizContext)
  const fieldValue = getFieldByName(name)
  const contextValue = !!fieldValue ? fieldValue : ''

  useEffect(() => {
    setIsValid(validateField(name, validator))
  }, [contextValue, name, validateField, validator])

  const onChange = (name: string, mask?: Mask) => (e: React.FormEvent<HTMLInputElement>): void => {
    const value = e.currentTarget.value

    if (mask) {
      const maskedInput = conformToMask(
        value,
        mask,
        { guide: false }
      )

      // workaround to handle mask and Delta missing value prop
      e.currentTarget.value = maskedInput.conformedValue

      props?.onChange?.(e)

      return setFieldValue(name, maskedInput.conformedValue)
    }

    return setFieldValue(name, e.currentTarget.value)
  }
  const onKeyDown: (name: string, mask?: Mask) => React.KeyboardEventHandler<HTMLInputElement> = (name, mask) => (e): void => {
    if (mask && e.key === 'Backspace') {
      e.preventDefault()
      const newValue = e.currentTarget.value.substring(0, e.currentTarget.value.length - 1)

      // workaround to handle mask and Delta missing value prop
      e.currentTarget.value = newValue
      return setFieldValue(name, newValue)
    }
  }
  const inputValue = (value?: string | number | readonly string[]) => !value ? contextValue : `${value}`
  const inputStatus = (error: boolean, value?: string | number | readonly string[]) => {
    const status = !!value ? 'success' : 'default'
    return triedToSubmit && error ? 'error' : status
  }

  return (
    <Holder css={css}>
      <Input
        onKeyDown={onKeyDown(name, mask)}
        defaultValue={inputValue(value)}
        status={inputStatus(!isValid, value)}
        id={id}
        className={className}
        name={name}
        placeholder={placeholder}
        label={label || ''}
        required={!!required}
        helperText={!isValid && triedToSubmit ? validator.msg(contextValue) : helperText}
        {...props}
        onChange={onChange(name, mask)}
      />
    </Holder>
  )
}

export default TextInput
