import React from 'react'
import { TStepProps } from '@escaletech/quiz'
import { TextInput, RequiredField } from '@packages/components'

import { QuestionHeader } from '../../../components/QuestionHeader'

import { BaseLayout } from '../../../layouts/BaseLayout'

import { birthDateMask, numberMask } from '../../../utils/masks'
import { isBirthDateValid } from '../../../utils/isBirthDateValid'

export function DependentsAndDateOfBirth({
  ProgressBar,
  Navigation
}: TStepProps){
  return (
    <BaseLayout navigation={Navigation} progressBar={ProgressBar}>
      <QuestionHeader
        title="Informe a data de nascimento do titular do plano"
      />

      <TextInput
        label="Digite a data de nascimento"
        name="birth_date"
        id="birth_date"
        mask={birthDateMask}
        required
        validator={{
          func: (fieldValue = '') => isBirthDateValid(fieldValue),
          msg: (fieldValue = '') => {
            if (!fieldValue.trim()) {
              return 'Campo obrigatório'
            }

            return 'Data inválida'
          }
        }}
        css={{ marginBottom: '$lg' }}
      />

      <QuestionHeader
        title="Quantos dependentes gostaria de incluir?"
        description="É necessário, pelo menos, um dependente em um plano contratado por CNPJ."
      />

      <TextInput
        label="Digite o número de dependentes"
        name="n__total_de_pessoas"
        id="n__total_de_pessoas"
        mask={numberMask}
        required
        validator={RequiredField}
      />
    </BaseLayout>
  )
}
