import React, { useContext, useEffect, useState, ComponentProps } from 'react'
import { QuizContext } from '@escaletech/quiz'
import { Radio as RadioDelta } from '@escaletech/delta'

import { FieldError } from '@packages/components'

import { TInputWithFieldOptions } from '../types'
import { ValidatorDefaultValue } from '../constants'

import * as S from './Radio.styles'

type RadioProps = ComponentProps<typeof S.RadioGroup> & TInputWithFieldOptions

const Radio = ({
  options,
  name,
  validator = ValidatorDefaultValue,
  ...props
}: RadioProps) => {
  const [isValid, setIsValid] = useState(true)
  const { setFieldValue, getFieldByName, validateField } = useContext(QuizContext)
  const fieldValue = getFieldByName(name) || ''

  useEffect(() => {
    setIsValid(validateField(name, validator))
  }, [fieldValue, name, validateField, validator])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(event.currentTarget.name, event.currentTarget.value)
  }

  return (
    <S.Wrapper {...props}>
      <S.RadioGroup>
        {options?.map(({ label = '', value, id, className }, index) => (
          <RadioDelta
            key={index}
            id={id}
            checked={fieldValue === value}
            className={className}
            name={name}
            label={label}
            value={value}
            onChange={handleChange}
          />
        ))}
      </S.RadioGroup>
      {!isValid && <FieldError msg={validator.msg(fieldValue)} />}
    </S.Wrapper>
  )
}

Radio.displayName = 'Radio'

export default Radio
