import { styled } from "@escaletech/delta"

export const SelectGroup = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$xxxs',

  margin: '$xs 0 $sm 0',

  '@md': {
    margin: '$xs 0 $xl 0'
  }
})
