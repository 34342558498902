import { ApolloProvider } from '@apollo/client'
import apolloClient from '@packages/services/src/apolloClient'
import React from 'react'
import ReactDOM from 'react-dom'
import HealthQuiz from './HealthQuiz'

ReactDOM.render(
<ApolloProvider client={apolloClient}>
  <React.StrictMode>
    <HealthQuiz />
  </React.StrictMode>
</ApolloProvider>, document.getElementById('root'))

