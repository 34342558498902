import { styled } from "@escaletech/delta"

export const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$xs',
  alignItems: 'center',
  textAlign: 'center',

  '@md': {
    alignItems: 'start',
    textAlign: 'left',
  }
})

export const Check = styled('img', {
  width: '96px',
})
