import React from 'react'
import { TStepProps, TValidator } from '@escaletech/quiz'
import { Radio, RequiredField, TextInput } from '@packages/components'

import { BaseLayout } from '../../../layouts/BaseLayout'

import { QuestionHeader } from '../../../components/QuestionHeader'

import { isZipcodeValid } from '../../../utils/isZipcodeValid'
import { zipcodeMask } from '../../../utils/masks'

import { useIntro } from './useIntro'

export function Intro({ ProgressBar, Navigation }: TStepProps){
  const { handleZipcodeChange } = useIntro()

  return (
    <BaseLayout navigation={Navigation} progressBar={ProgressBar}>
      <QuestionHeader
        title="Simplifique sua busca pelo plano ideal!"
        description="Na Zelas Saúde é fácil: responda algumas perguntinhas e nossos parceiros montam uma cotação personalizada para você."
      />

      <QuestionHeader
        title="Vamos começar. Qual seu CEP?"
        description="Assim poderemos encontrar planos com médicos, hospitais e laboratórios perto de você."
      />

      <TextInput
        label="Digite seu CEP"
        name="zip_code"
        id="zip_code"
        required
        onChange={handleZipcodeChange}
        mask={zipcodeMask}
        validator={{
          func: (fieldValue = '') => isZipcodeValid(fieldValue),
          msg: (fieldValue = '') => {
            if (!fieldValue.trim()) {
              return 'CEP inváldo'
            }

            return 'CEP inváldo'
          }
        } as TValidator}
        css={{
          marginBottom: '$sm',

          '@md': {
            marginBottom: '$lg'
          }
        }}
      />

      <QuestionHeader
        title="Que tipo de plano você deseja contratar?"
      />

      <Radio
        name="tipo_plano___quiz"
        validator={RequiredField}
        options={[
          {
            value: 'Contratar Plano de Saúde',
            label: 'Plano de Saúde',
            id: 'plano-de-saude',
            className: 'tipo-plano'
          },
          {
            value: 'Contratar Plano Odontológico',
            label: 'Plano Odontológico',
            id: 'plano_odontologico',
            className: 'tipo-plano'
          }
        ]}
        required
        css={{ marginBottom: '$xs' }}
      />


    </BaseLayout>
  )
}
