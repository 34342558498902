import { styled } from "@escaletech/delta"

export const Header = styled('header', {
  height: 'var(--header-height)',
  padding: '0 1.5rem',
  width: '100%',
  display: 'flex',
  alignItems: 'center',

  '@lg': {
    display: 'none'
  }
})
