import { styled } from '@escaletech/delta'

export const InputNumber = styled('div', {
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  padding: '0px',
  gap: '10px'
})

export const Input = styled('input', {
  textAlign: 'center',
  padding: 0,
  gap: '10px',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '150%',
  width: '32px',
  height: '32px',
  background: '#F7F7F7',
  borderRadius: '4px',
  border: 'transparent',

  '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
    appearance: 'none',
    margin: 0
  }
})

export const Button = styled('button', {
  border: 'none',
  cursor: 'pointer',
  background: 'transparent',
  outline: 'none',
  opacity: 1,
  padding: 0,

  '&[disabled]': {
    cursor: 'not-allowed',
    opacity: 0.5
  },

  'img': {
    height: '36px',
    pointerEvents: 'none'
  }
})
