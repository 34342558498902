import { TValidator } from "./types"

export const ValidatorDefaultValue: TValidator = {
  func: () => true,
  msg: () => ''
}

export const RequiredField: TValidator = {
  func: (fieldValue) => !!fieldValue,
  msg: () => 'Campo obrigatório'
}
