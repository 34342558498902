import React from 'react'
import { TStepProps } from '@escaletech/quiz'
import { Radio, RequiredField } from '@packages/components'

import { QuestionHeader } from '../../../components/QuestionHeader'

import { BaseLayout } from '../../../layouts/BaseLayout'

export function Budget({
  ProgressBar,
  Navigation
}: TStepProps){
  return (
    <BaseLayout navigation={Navigation} progressBar={ProgressBar}>
      <QuestionHeader
        title="Legal! Agora conta pra gente qual valor mensal que cabe no seu bolso."
      />

      <Radio
        name="valores___quiz"
        validator={RequiredField}
        options={[
          {
            value: 'Até R$500',
            label: 'Até R$500',
            id: 'valor_plano_orcamento_plano_500',
            className: 'orcamento_plano'
          },
          {
            value: 'De R$500 a R$750',
            label: 'De R$500 a R$750',
            id: 'valor_plano_orcamento_plano_500_750',
            className: 'orcamento_plano'
          },
          {
            value: 'De R$750 a R$1.000',
            label: 'De R$750 a R$1.000',
            id: 'valor_plano_orcamento_plano_750_1000',
            className: 'orcamento_plano'
          },
          {
            value: 'Acima de R$1.000',
            label: 'Acima de R$1.000',
            id: 'valor_plano_orcamento_plano_1000',
            className: 'orcamento_plano'
          }
        ]}
        required
      />
    </BaseLayout>
  )
}
