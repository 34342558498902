export const baseTokens = {
  borderWidths: {},
  colors: {
    alertBorderColor: '$primaryMedium',
    alertBackgroundColor: '$neutralUltralight',
    alertIconColor: '$primaryMedium'
  },
  fonts: {},
  fontSizes: {},
  fontWeights: {},
  lineHeights: {},
  opacities: {},
  radii: {},
  shadows: {},
  space: {},
  sizes: {},
  transitions: {},
  zIndices: {}
}
