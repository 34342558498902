import { styled, keyframes } from '@escaletech/delta'

const expandAnimation = keyframes({
  '0%': {
    transform: 'scale(0)',
    opacity: 0
  },

  '100%': {
    transform: 'scale(1)',
    opacity: 1,
  }
})

const S = {
  Wrapper: styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: '$alwaysOnTop',
  }),
  Overlay: styled('div', {
    display: 'none',

    '@md': {
      display: 'block',
      width: '100vw',
      height: '100vh',
      position: 'absolute',
      zIndex: '$alwaysOnTop',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
  }),
  Dialog: styled('div', {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#fff',
    position: 'fixed',
    zIndex: 27,
    padding: '$md $xxs',
    overflow: 'hidden',

    '@md': {
      margin: 'auto',
      boxShadow: '0px 8px 32px rgba(0, 0, 0, 0.4)',
      borderRadius: '8px',
      width: 'auto',
      height: 'auto',
      padding: '$md',
      animation: `${expandAnimation} 300ms`,
    }
  }),
  Close: styled('div', {
    cursor: 'pointer',
    position: 'absolute',
    top: 16,
    right: 24,

    '@md': {
      top: 24
    }
  })
}

export default S
