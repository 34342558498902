import React, { useContext } from 'react'

import { QuizContext } from '@escaletech/quiz'

import { TFieldErrorComponent } from '../types'
import * as S from './styles'

const FieldError: TFieldErrorComponent = ({ msg }) => {
  const { triedToSubmit } = useContext(QuizContext)
  return triedToSubmit ? (
    <S.Error>{msg}</S.Error>
  ) : null
}

export default FieldError
