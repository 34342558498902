import React from 'react'
import { Heading, Paragraph } from "@escaletech/delta"

import GreenCheck from '../../../../../assets/images/green-check.svg'

import * as S from './Message.styles'

export function Message(){
  return (
    <S.Wrapper>
      <S.Check src={GreenCheck} alt="Check verde de sucesso" />
      <Heading size="lg" weight="bold" css={{ maxWidth: '21rem' }}>
        Avaliação enviada com sucesso
      </Heading>
      <Paragraph size={{ '@initial': 'md', '@md': 'lg' }}>
        Em breve, um(a) especialista parceiro(a) entrará em contato para finalizar a sua cotação.
      </Paragraph>
    </S.Wrapper>
  )
}
