import 'cross-fetch/polyfill'
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

 const httpLink = createHttpLink({
  uri: process.env.BFF_ENDPOINT || 'https://api.escale.com.br/escale-os-bff'
})

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      origin: 'https://saude.zelas.com.br',
      authorization:
        process.env.BFF_KEY ||
        'Basic TjJFek56RTNOMkl4TWpsbE5HVTNabUV3WWpreFl6UTROelV3WTJJd05EST06YzBvMVRsRjVaM2R4Y0hWVmMwOUJObmRhUVVKQ05EaDFWMjB3YkdreU0zRnBNMDlVUlRaamJnPT0='
    }
  }
})

export default new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})
