import React from 'react'
import { TStepProps } from '@escaletech/quiz'
import { Radio, RequiredField } from '@packages/components'
import { Alert, Paragraph } from '@escaletech/delta'

import { QuestionHeader } from '../../../components/QuestionHeader'

import { BaseLayout } from '../../../layouts/BaseLayout'

export function AccommodationAndPriorities({
  ProgressBar,
  Navigation
}: TStepProps){
  return (
    <BaseLayout navigation={Navigation} progressBar={ProgressBar}>
      <QuestionHeader
        title="Você prefere qual tipo de acomodação?"
        description="Acomodação é o local que o paciente ficará em caso de internação."
      />

      <Radio
        name="acomodacao___quiz"
        validator={RequiredField}
        options={[
          {
            value: 'Enfermaria',
            label: 'Enfermaria',
            id: 'enfermaria_preferencia_acomodacao',
            className: 'preferencia_acomodacao'
          },
          {
            value: 'Apartamento',
            label: 'Apartamento',
            id: 'apartamento_preferencia_acomodacao',
            className: 'preferencia_acomodacao'
          },
          {
            value: 'Não tenho preferência',
            label: 'Não tenho preferência',
            id: 'nao_tenho_preferencia_preferencia_acomodacao',
            className: 'preferencia_acomodacao'
          }
        ]}
        required
        css={{ marginBottom: '$xs' }}
      />

      <Alert title='Dica da Zelas:'>
        <Paragraph>
          O apartamento é um espaço privado. A enfermaria é compartilhada com até 4 pessoas, sendo a opção mais barata.
        </Paragraph>
      </Alert>
    </BaseLayout>
  )
}
