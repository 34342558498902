export const headerTokens = {
  borderWidths: {},
  colors: {},
  fonts: {},
  fontSizes: {},
  fontWeights: {},
  lineHeights: {},
  opacities: {},
  radii: {},
  shadows: {},
  space: {},
  sizes: {},
  zIndices: {}
}
