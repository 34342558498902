import { styled } from "@escaletech/delta"

export const Wrapper = styled('div', {
  height: '100%',
})

export const ButtonGroup = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '$xxs',
  marginTop: '$xs',

  '@md': {
    marginTop: '80px'
  },

  '&:first-child': {
    '&:hover': {
      '.icon': {
        borderColor: '#fff'
      }
    }
  }
})

export const ButtonArrow = styled('div', {
  width: '10px',
  height: '10px',
  borderTop: '1px solid #000',
  borderLeft: '1px solid #000',
  transform: 'rotate(315deg)'
})
