import { styled } from '@escaletech/delta'

export const CES = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '$sm',
  '@md': {
    alignItems: 'flex-start',
  }
})

export const Options = styled('div', {
  display: 'flex',
  position: 'relative',
  width: '100%',
  justifyContent: 'center',
  fontSize: '$md',
  marginBottom: '$nano',
  '@md': {
    justifyContent: 'left',
  }
})

export const Option = styled('div', {
  width: '56px',
  height: '56px',
  marginRight: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
  border: '1px solid $primaryMedium',
  cursor: 'pointer',
  transition: 'all .3s ease-in',
  '&:hover': {
    backgroundColor: '$neutralLightest'
  },
  '&:last-child': {
    marginRight: 0
  },
  '&.active': {
    color: '$neutralUltralight',
    backgroundColor: '$primaryMedium'
  },
})

export const Labels = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  fontSize: '$xxxs',
  color: '$neutralDark',
  maxWidth: '312px'
})
