import { baseTokens } from './base'
import { buttonTokens } from './button'
import { headerTokens } from './header'
import { headingTokens } from './heading'
import { linkTokens } from './link'
import { paragraphTokens } from './paragraph'

export const componentTokens = {
  borderWidths: {
    ...buttonTokens.borderWidths,
    ...headerTokens.borderWidths,
  },
  colors: {
    ...buttonTokens.colors,
    ...headerTokens.colors,
    ...linkTokens.colors,
    ...baseTokens.colors
  },
  fonts: {
    ...buttonTokens.fonts,
    ...headerTokens.fonts,
    ...headingTokens.fonts,
    ...linkTokens.fonts,
    ...paragraphTokens.fonts,
  },
  fontSizes: {
    ...buttonTokens.fontSizes,
    ...headerTokens.fontSizes,
    ...linkTokens.fontSizes
  },
  fontWeights: {
    ...buttonTokens.fontWeights,
    ...headerTokens.fontWeights,
    ...headingTokens.fontWeights,
    ...linkTokens.fontWeights,
    ...paragraphTokens.fontWeights
  },
  lineHeights: {
    ...headerTokens.lineHeights
  },
  opacities: {
    ...headerTokens.opacities,
  },
  radii: {
    ...buttonTokens.radii,
    ...headerTokens.radii,
  },
  shadows: {
    ...headerTokens.shadows,
    ...linkTokens.shadows,
  },
  space: {
    ...baseTokens.space,
    ...buttonTokens.space,
    ...headerTokens.space,
  },
  sizes: {
    ...buttonTokens.sizes,
    ...headerTokens.sizes,
    ...baseTokens.sizes
  },
  transitions: {},
  zIndices: {
    ...headerTokens.zIndices,
  }
}
