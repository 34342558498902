import { styled } from '@escaletech/delta'

export const InputNumber = styled('div')

export const Header = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '15px 0',
  margin: '0 15px',

  '@md': {
    display: 'none'
  }
})

export const LabelNumber = styled('label', {
  whiteSpace: 'nowrap',
  color: '$color-neutral-dark'
})

export const Row = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '15px 0',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '150%',
  color: '#4D4D4D',
  borderBottom: 'solid 2px #F7F7F7',

  '&:last-of-type': {
    borderBottom: 'transparent'
  }
})

export const Body = styled('div', {
  padding: '0 15px',
  border: '1px solid #C7C7C7',
  borderRadius: '8px',
  backgroundColor: '#fff'
})

export const HiddenInput = styled('input', {
  display: 'none'
})

export const Total = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '$xxxs',
  height: '88px',
  fontSize: '$xxs',
  border: '1px solid $neutralLight',
  borderRadius: '8px',
  marginTop: '$nano',
  color: '$neutralDark',
  backgroundColor: '#fff'
})

export const TotalValue = styled('span', {
  position: 'relative',
  paddingRight: '38px',
  '@md': {
    paddingRight: '54px'
  },
  '&:before': {
    content: '',
    display: 'block',
    backgroundColor: '$neutralLight',
    height: '40px',
    width: '1px',
    position: 'absolute',
    left: '-78px',
    top: 0,
    bottom: 0,
    margin: 'auto'
  }
})
