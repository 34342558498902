import { Paragraph, styled } from "@escaletech/delta"

export const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$xxxs'
})

export const OptInField = styled('div', {
  display: 'flex',
  alignItems: 'start',
  gap: '$xxxs'
})

export const OptInText = styled(Paragraph, {
  display: 'inline',

  strong: {
    fontWeight: 'bold'
  },

  variants: {
    hiddenText: {
      true: {
        display: '-webkit-box',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
      }
    }
  }
})

export const SeeMoreButton = styled('span', {
  fontSize: '$xxxs',
  color: '$primaryMedium',
  textDecoration: 'underline',
  cursor: 'pointer'
})
