import React, { useEffect, useState } from 'react'
import { TStepProps } from '@escaletech/quiz'
import { RichText } from 'prismic-reactjs'
import { Card, Image, Paragraph, Button, Container } from '@escaletech/delta'

import { BaseLayout } from '../../../layouts/BaseLayout'

export const DentalPlan = ({ ProgressBar, Navigation }: TStepProps)  => {
  const [odontoPlanPage, setOdontoPlanPage] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(true)

  async function loadDocument() {
    try{
      const response = await fetch('https://tudo-saude-escale.prismic.io/api/v2/')

      const data = await response.json()

      const ref = data.refs?.[0]?.ref

      const documentResponse = await fetch(`https://tudo-saude-escale.prismic.io/api/v2/documents/search?ref=${ref}&q=[[at(my.odonto_plan.uid,"odonto-plan-quiz")]]`)

      const document = await documentResponse.json()

      setOdontoPlanPage(document)
    } catch(error) {
      console.error({ error })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadDocument()
  }, [])

  const operators = odontoPlanPage?.results?.[0]?.data?.operators
  const operatorFilled = operators?.length > 0
  return (
    <BaseLayout navigation={Navigation} progressBar={ProgressBar}>
      {isLoading ? null : (
        <>
          <Paragraph
            as='div'
            css={{
              marginBottom: '1.5rem',
              h1: {
                fontSize: '2rem',
                fontWeight: 'bold',
              },
              strong: {
                color: '#4d4d4d',
                fontWeight: 'bold',
                lineHeight: 1.2,
                fontSize: '1.25rem',
              },
          }}>
          <RichText render={odontoPlanPage?.results?.[0]?.data?.content} />
          </Paragraph>
          <div id='odonto-plan' style={{ marginBottom: '2rem' }}>
          <Container css={{
              display: 'flex',
              gap: '1rem',
              flexDirection: 'column',
              padding: 0,
              '@sm': {
                flexDirection: 'row'
              }
            }}>
            {
              operatorFilled &&
              (
                operators.map((operator: Record<string, any>) => {
                const{image, link} = operator
                return (
                  <Card
                    as='a'
                    // @ts-expect-error: Card will have HREF prop when defined as <a>
                    href={link ?? ''}
                    key={image.alt}
                    target='_blank'
                    rel='noopener noreferrer'
                    id={`odonto-plan-${image.alt}`}
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      padding: 40,
                      marginBottom: '1.5rem',
                      img: {
                        maxWidth: '100%',
                        height: 'auto'
                      }
                    }}
                  >
                    <Image
                      src={image.url}
                      alt={image.alt}
                      css={{
                        marginBottom: '1.5rem',
                      }}
                    />
                    <Button
                      css={{
                      fontWeight: 600,
                      }}
                      fullWidth
                      type='button'
                    >
                      Conhecer os planos
                    </Button >
                  </Card>
                )
              }
            ))
            }
          </Container>
          <Paragraph
            as='div'
            css={{
                marginBottom: '1.5rem',
                h1: {
                  fontSize: '2rem',
                  fontWeight: 'bold',
              },
              strong: {
                color: '#4d4d4d',
                fontWeight: 'bold',
                lineHeight: 1.2,
                fontSize: '1.25rem',
              },
          }}>
          <RichText render={odontoPlanPage?.results?.[0]?.data?.helperText} />
          </Paragraph>
          </div>
        </>
      )}
    </BaseLayout>
  )
}
