
import React from 'react'
import { TStepProps } from '@escaletech/quiz'
import { TextInput, RequiredField } from '@packages/components'
import { Alert, Paragraph } from '@escaletech/delta'

import { QuestionHeader } from '../../../components/QuestionHeader'

import { BaseLayout } from '../../../layouts/BaseLayout'

import { birthDateMask } from '../../../utils/masks'
import { isBirthDateValid } from '../../../utils/isBirthDateValid'

export function ProfessionAndDateOfBirth({
  ProgressBar,
  Navigation
}: TStepProps){
  return (
    <BaseLayout navigation={Navigation} progressBar={ProgressBar}>
      <QuestionHeader
        title="Qual a sua formação/profissão?"
        description='Isso nos ajudará a entender se você é elegível para um plano de saúde por adesão.'
      />

      <TextInput
        label="Digite sua formação/profissão"
        name="occupation"
        id="occupation"
        required
        validator={RequiredField}
        css={{ marginBottom: '$lg' }}
      />

      <QuestionHeader
        title="Qual a sua data de nascimento?"
      />

      <TextInput
        label="Digite a data de nascimento"
        name="birth_date"
        id="birth_date"
        mask={birthDateMask}
        required
        validator={{
          func: (fieldValue = '') => isBirthDateValid(fieldValue),
          msg: (fieldValue = '') => {
            if (!fieldValue.trim()) {
              return 'Campo obrigatório'
            }

            return 'Data inválida'
          }
        }}
        css={{ marginBottom: '$xs' }}
      />

      <Alert title='Dica da Zelas:'>
        <Paragraph css={{ strong: { fontWeight: '$bold' } }}>
          Neste momento, informe os dados do titular do plano. Se não tiver uma profissão, digite <strong>Não tenho</strong>, e se ainda estiver estudando, digite <strong>Estudante</strong>.
        </Paragraph>
      </Alert>
    </BaseLayout>
  )
}
