import React, { useContext, useState } from 'react'
import { QuizContext } from '@escaletech/quiz'
import { Checkbox, Paragraph } from "@escaletech/delta"

import * as S from './OptInGroup.styles'

export function OptInGroup(){
  const [showAllText, setShowAllText] = useState(false)

  const { getFieldByName, setFieldValue } = useContext(QuizContext)
  const dataOptInIsChecked = getFieldByName('opt_in_parceiros') === 'accepted'
  const marketingOptInIsChecked = getFieldByName('opt_in_mkt') === 'accepted'

  function handleOptInChange(event: React.ChangeEvent<HTMLInputElement>) {
    if(event.target.checked){
      return setFieldValue(event.target.name, 'accepted')
    }

    setFieldValue(event.target.name, 'declined')
  }

  function toggleShowAllText(){
    setShowAllText((prevState) => !prevState)
  }

  return (
    <S.Wrapper>
      <Paragraph>
        Seus dados vão ser usados para cotação do seu plano. Confira nossa <a href="https://escale.com.br/portal-de-privacidade" target="_blank" rel="noopener noreferrer">Portal de Privacidade</a>.
      </Paragraph>

      <S.OptInField>
        <label>
          <Checkbox
            name="opt_in_parceiros"
            onChange={handleOptInChange}
            checked={dataOptInIsChecked}
          />
        </label>

        <div>
          <S.OptInText
            size="xs"
            hiddenText={!showAllText}
            css={{ color: '$neutralDark' }}
          >
            Autorizo o compartilhamento dos meus dados com parceiros de Zelas: empresas e consultores de vendas em minha região, para que eu receba várias cotações e ofertas de produtos de saúde e possa escolher a melhor opção para mim. <strong>O não compartilhamento dos dados implica na impossibilidade de receber cotações.</strong>
          </S.OptInText>
          {' '}
          <S.SeeMoreButton
            onClick={toggleShowAllText}
            tabIndex={0}
            role='button'
          >
            {!showAllText ? 'Ver mais' : 'Ver menos'}
          </S.SeeMoreButton>
        </div>
      </S.OptInField>

      <S.OptInField>
        <label>
          <Checkbox
            name="opt_in_mkt"
            onChange={handleOptInChange}
            checked={marketingOptInIsChecked}
          />
        </label>

        <S.OptInText size="xs" css={{ color: '$neutralDark' }}>
          Quero receber comunicações da Zelas Saúde sobre novas ofertas, promoções e outras informações.
        </S.OptInText>
      </S.OptInField>
    </S.Wrapper>
  )
}
