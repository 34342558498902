import { styled } from '@escaletech/delta'

export const Wrapper = styled('select', {
  width: '100%',
  height: '4rem',
  border: 0,
  backgroundColor: 'white',
  borderColor: '$formFieldBorderColor',
  borderRadius: '$xxs',
  borderWidth: '$hairline',
  borderStyle: 'solid',
  color: '$formFieldLabelColor',
  fontFamily: '$formFieldFontFamily',
  fontSize: '$xxs',
  fontWeight: '$regular',
  padding: '0 $xxxs',

  '&:enabled:hover': {
    borderColor: '$formFieldBorderColorHover',
    color: '$formFieldLabelColorHover'
  },

  '&:enabled:focus': {
    outline: '4px solid $focusedLight',
    borderColor: '$focused !important'
  },

  '&:disabled': {
    backgroundColor: '$formFieldBackgroundColorDisabled',
    borderColor: '$formFieldBorderColorDisabled',
    color: '$formFieldColorDisabled',
    cursor: 'not-allowed'
  }
})
