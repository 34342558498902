import React from 'react'
import { Image } from '@escaletech/delta'

import logoImg from '../../assets/images/logo.svg'

import * as S from './Sidebar.styles'

export const Sidebar = () => (
  <S.Wrapper>
    <a href="https://saude.zelas.com.br/" aria-label="Ir para página inicial do Zelas Saúde">
      <S.LogoWrapper>
        <Image
          src={logoImg}
          alt='Zelas Saude'
          loading="eager"
          css={{
            width: '100%',
            maxWidth: '10rem'
          }}
        />
      </S.LogoWrapper>
    </a>
  </S.Wrapper>
)
